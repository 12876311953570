const adminRoutes = [
    {
        path: "/not-found",
        name: "page-not-found",
        component: () => import("../../pages/PageNotFound.vue"),
    },
    {
        path: "/",
        name: "home",
        component: () => import("../../pages/Index.vue"),
        meta: {
        requiresAuth: true,
        },
    },
    {
        path: "users",
        name: "users",
        component: () => import("../../pages/user/User.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["user-list"],
        },
    },
    {
        path: "users/create",
        name: "user-create",
        component: () => import("../../pages/user/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["user-create"],
        },
    },
    {
        path: "users/:id/edit",
        name: "user-update",
        component: () => import("../../pages/user/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["user-edit"],
        },
    },
    {
        path: "roles",
        name: "roles",
        component: () => import("../../pages/role/Role.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["role-list"],
        },
    },
    {
        path: "roles/create",
        name: "role-create",
        component: () => import("../../pages/role/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["role-create"],
        },
    },
    {
        path: "roles/:id/edit",
        name: "role-update",
        component: () => import("../../pages/role/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["role-edit"],
        },
    },
    {
        path: "permissions",
        name: "permissions",
        component: () => import("../../pages/permission/Permission.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["permission-list"],
        },
    },
    {
        path: "permissions/create",
        name: "permission-create",
        component: () => import("../../pages/permission/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["permission-create"],
        },
    },
    {
        path: "permissions/:id/edit",
        name: "permission-update",
        component: () => import("../../pages/permission/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["permission-edit"],
        },
    },

    {
        path: "dashboard",
        name: "dashboard",
        component: () => import("../../pages/dashboard/Dashboard.vue"),
        // meta: {
        //   requirePermissions: true,
        //   permissions: ["dashboard"],
        // },
    },

    {
        path: "trainers",
        name: "trainers",
        component: () => import("../../pages/trainer/Trainer.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-list"],
        },
    },
    {
        path: "trainers/create",
        name: "trainer-create",
        component: () => import("../../pages/trainer/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-create"],
        },
    },
    {
        path: "trainers/:id/edit",
        name: "trainer-update",
        component: () => import("../../pages/trainer/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-edit"],
        },
    },
    {
        path: "trainers/:id/delete",
        name: "trainer-delete",
        component: () => import("../../pages/trainer/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-delete"],
        },
    },

    {
        path: "trainer-profile",
        name: "trainer-profile",
        component: () => import("../../pages/trainer-profile/TrainerProfile.vue"),
        // meta: {
        //   requirePermissions: true,
        //   permissions: ["trainer-profile-list"],
        // },
    },
    {
        path: "trainer-profile/:id/detail",
        name: "trainer-profile-detail",
        component: () =>
        import("../../pages/trainer-profile/TrainerProfileDetail.vue"),
        // meta: {
        //   requirePermissions: true,
        //   permissions: ["trainer-profile-detail"],
        // },
    },

    {
        path: "modules",
        name: "modules",
        component: () => import("../../pages/module/Module.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["module-list"],
        },
    },
    {
        path: "modules/create",
        name: "module-create",
        component: () => import("../../pages/module/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["module-create"],
        },
    },
    {
        path: "modules/:id/edit",
        name: "module-update",
        component: () => import("../../pages/module/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["module-edit"],
        },
    },
    {
        path: "modules/:id/delete",
        name: "module-delete",
        component: () => import("../../pages/module/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["module-delete"],
        },
    },

    {
        path: "module-groups",
        name: "module-groups",
        component: () => import("../../pages/module-group/ModuleGroup.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["module-group-list"],
        },
    },
    {
        path: "module-groups/create",
        name: "module-group-create",
        component: () => import("../../pages/module-group/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["module-group-create"],
        },
    },
    {
        path: "module-groups/:id/edit",
        name: "module-group-update",
        component: () => import("../../pages/module-group/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["module-group-edit"],
        },
    },
    {
        path: "module-groups/:id/delete",
        name: "module-group-delete",
        component: () => import("../../pages/module-group/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["module-group-delete"],
        },
    },

    {
        path: "module-handover",
        name: "module-handover",
        component: () => import("../../pages/module-handover/ModuleHandover.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["module-handover-list"],
        },
    },
    {
        path: "module-handover-detail/:id",
        name: "module-handover-detail",
        component: () =>
        import("../../pages/module-handover/ModuleHandoverDetail.vue"),
        // meta: {
        //   requirePermissions: true,
        //   permissions: ["module-handover-detail"],
        // },
    },
    {
        path: "module-handover/create",
        name: "module-handover-create",
        component: () => import("../../pages/module-handover/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["module-handover-create"],
        },
    },
    {
        path: "module-handover/:id/edit",
        name: "module-handover-update",
        component: () => import("../../pages/module-handover/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["module-handover-edit"],
        },
    },
    {
        path: "module-handover/:id/delete",
        name: "module-handover-delete",
        component: () => import("../../pages/module-handover/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["module-handover-delete"],
        },
    },

    {
        path: "module-handover-approval",
        name: "module-handover-approval",
        component: () =>
        import("../../pages/module-handover-approval/HandoverApproval.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["module-handover-approval-list"],
        },
    },
    {
        path: "module-handover-approval/:id/edit",
        name: "module-handover-approval-update",
        component: () => import("../../pages/module-handover-approval/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["module-handover-approval-edit"],
        },
    },

    {
        path: "module-deactivate",
        name: "module-deactivate",
        component: () =>
        import("../../pages/module-deactivate/ModuleDeactivation.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["module-deactivate-list"],
        },
    },
    {
        path: "module-deactivate/:id/edit",
        name: "module-deactivate-update",
        component: () => import("../../pages/module-deactivate/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["module-deactivate-edit"],
        },
    },

    {
        path: "program-owners",
        name: "program-owners",
        component: () => import("../../pages/program-owner/ProgramOwner.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["program-owner-list"],
        },
    },
    {
        path: "program-owners/create",
        name: "program-owner-create",
        component: () => import("../../pages/program-owner/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["program-owner-create"],
        },
    },
    {
        path: "program-owners/:id/edit",
        name: "program-owner-update",
        component: () => import("../../pages/program-owner/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["program-owner-edit"],
        },
    },
    {
        path: "program-owners/:id/delete",
        name: "program-owner-delete",
        component: () => import("../../pages/program-owner/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["program-owner-delete"],
        },
    },

    {
        path: "program-owner-handover",
        name: "program-owner-handover",
        component: () =>
        import("../../pages/program-owner-handover/ProgramOwnerHandover.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["program-owner-handover-list"],
        },
    },
    {
        path: "program-owner-handover-detail/:id",
        name: "program-owner-handover-detail",
        component: () =>
        import(
            "../../pages/program-owner-handover/ProgramOwnerHandoverDetail.vue"
        ),
        // meta: {
        //   requirePermissions: true,
        //   permissions: ["program-owner-handover-detail"],
        // },
    },
    {
        path: "program-owner-handover/create",
        name: "program-owner-handover-create",
        component: () => import("../../pages/program-owner-handover/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["program-owner-handover-create"],
        },
    },
    {
        path: "program-owner-handover/:id/edit",
        name: "program-owner-handover-update",
        component: () => import("../../pages/program-owner-handover/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["program-owner-handover-edit"],
        },
    },

    {
        path: "program-handover-approval",
        name: "program-handover-approval",
        component: () =>
        import("../../pages/program-handover-approval/HandoverApproval.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["program-handover-approval-list"],
        },
    },
    {
        path: "program-handover-approval/:id/edit",
        name: "program-handover-approval-update",
        component: () => import("../../pages/program-handover-approval/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["program-handover-approval-edit"],
        },
    },

    {
        path: "yearly-training-plans",
        name: "yearly-training-plan-index",
        component: () => import("../../pages/yearly-training-plan/BudgetList.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["yearly-training-plan-index"],
        },
    },
    {
        path: "yearly-training-plans/:id",
        name: "yearly-training-plan-show",
        component: () => import("../../pages/yearly-training-plan/PlanDetail.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["yearly-training-plan-show"],
        },
    },
    {
        path: "yearly-training-plans/:id/add-plan",
        name: "yearly-training-plan-create",
        component: () => import("../../pages/yearly-training-plan/AddPlan.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["yearly-training-plan-create"],
        },
    },

    {
        path: "monthly-plans",
        name: "monthly-plans",
        component: () => import("../../pages/monthly-plan/MonthlyPlan.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["monthly-schedule-plan-list"],
        },
    },
    {
        path: "monthly-plans-view-lists/:id/:month",
        name: "monthly-plans-view-lists",
        component: () =>
        import("../../pages/monthly-plan/MonthlyPlanViewLists.vue"),
        // meta: {
        //    requirePermissions: true,
        //    permissions: ["monthly-plans-view-lists"],
        // },
    },
    {
        path: "monthly-plan-calendar-view",
        name: "monthly-plan-calendar-view",
        component: () =>
        import("../../pages/monthly-plan/MonthlyPlanCalendarView.vue"),
        // meta: {
        //    requirePermissions: true,
        //    permissions: ["monthly-plan-calendar-view"],
        // },
    },
    // {
    //   path: "monthly-plans/:id/add-plan",
    //   name: "monthly-plan-create",
    //   component: () => import("../../pages/monthly-plan/Form.vue"),
    //   meta: {
    //     requirePermissions: true,
    //     permissions: ["monthly-plan-create"],
    //   },
    // },
    {
        path: "monthly-plans/add-plan",
        name: "monthly-plan-create",
        component: () => import("../../pages/monthly-plan/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["monthly-plan-create"],
        },
    },
    // {
    //    path: "monthly-plans/list",
    //    name: "monthly-plan-lists",
    //    component: () => import("../../pages/monthly-plan/MonthlyPlanLists.vue"),
    // },

    // {
    //    path: "enroll",
    //    name: "enroll",
    //    component: () => import("../../pages/monthly-plan/EnrollForm.vue"),
    // },
    {
        path: "monthly-plans/to-enroll",
        name: "monthly-plan-enroll",
        component: () => import("../../pages/monthly-plan/MonthlyPlansEnroll.vue"),
        // meta: {
        //    requirePermissions: true,
        //    permissions: ["monthly-plan-enroll-index"],
        // },
    },
    {
        path: "enroll/:id",
        name: "enroll-create",
        component: () => import("../../pages/monthly-plan/EnrollForm.vue"),
        // meta: {
        //    requirePermissions: true,
        //    permissions: ["monthly-plan-enroll-create"],
        // },
    },
    {
        path: "enrollment-approval",
        name: "enrollment-approval",
        component: () =>
        import("../../pages/monthly-plan-enroll/EnrollmentApproval.vue"),
        // meta: {
        //   requirePermissions: true,
        //   permissions: ["enrollment-approval"],
        // },
    },

    {
        path: "enrollment-approval/:id",
        name: "enrollment-approval-update",
        component: () => import("../../pages/monthly-plan-enroll/Form.vue"),
        // meta: {
        //    requirePermissions: true,
        //    permissions: ["enrollment-approval-update"],
        // },
    },
    {
        path: "enrollment-list",
        name: "enrollment-user-list",
        component: () => import("../../pages/monthly-plan-enroll/UserEnroll.vue"),
        // meta: {
        //    requirePermissions: true,
        //    permissions: ["enrollment-user-list"],
        // },
    },
    {
        path: "enrollment-user-view/:id",
        name: "enrollment-user-view",
        component: () => import("../../pages/monthly-plan-enroll/enrollData.vue"),
        // meta: {
        //    requirePermissions: true,
        //    permissions: ["enrollment-user-view"],
        // },
    },

    {
        path: "training-confirmation",
        name: "training-confirmation",
        component: () =>
        import("../../pages/training-confirmation/TrainingConfirmation.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["training-confirm-list"],
        },
    },
    {
        path: "training-confirmation/:id/edit",
        name: "training-confirm-edit",
        component: () => import("../../pages/training-confirmation/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["training-confirm-edit"],
        },
    },

    {
        path: "training-attendance",
        name: "training-attendance",
        component: () =>
        import("../../pages/training-attendance/TrainingAttendanceList.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["training-attendance-list"],
        },
    },
    {
        path: "training-attendance/:id/edit",
        name: "attendance-confirm-edit",
        component: () => import("../../pages/training-attendance/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["training-attendance-edit"],
        },
    },
    {
        path: "training-history",
        name: "training-history",
        component: () =>
        import(
            "../../pages/TrainingDatabase/training-history/TrainingHistory.vue"
        ),
        meta: {
        requirePermissions: true,
        permissions: ["training-history-list"],
        },
    },
    {
        path: "evaluation-history",
        name: "evaluation-history",
        component: () =>
        import(
            "../../pages/TrainingDatabase/evaluation-history/EvaluationHistory.vue"
        ),
        meta: {
        requirePermissions: true,
        permissions: ["evaluation-history-list"],
        },
    },
    {
        path: "evaluation-history/:id/detail",
        name: "evaluation-history-detail",
        component: () =>
        import(
            "../../pages/TrainingDatabase/evaluation-history/EvaluationHistoryDetail.vue"
        ),
        // meta: {
        //   requirePermissions: true,
        //   permissions: ["evaluation-history-detail"],
        // },
    },
    //------     Weight Management    ------//
    {
        path: "assessment-criteria-percentage",
        name: "assessment-criteria-percentage",
        component: () =>
        import(
            "../../pages/weight-management/assessment-criteria-percentage/Index.vue"
        ),
    },

    {
        path: "assessment-criteria-percentage/create",
        name: "assessment-criteria-percentage-create",
        component: () =>
        import(
            "../../pages/weight-management/assessment-criteria-percentage/Create.vue"
        ),
    },

    {
        path: "assessment-criteria-percentage/:id/edit",
        name: "assessment-criteria-percentage-edit",
        component: () =>
        import(
            "../../pages/weight-management/assessment-criteria-percentage/Edit.vue"
        ),
    },
    {
        path: "assessment-criteria",
        name: "assessment-criteria",
        component: () =>
        import("../../pages/weight-management/assessment-criteria/Index.vue"),
    },

    {
        path: "assessment-criteria/create",
        name: "assessment-criteria-create",
        component: () =>
        import("../../pages/weight-management/assessment-criteria/Create.vue"),
    },

    {
        path: "assessment-criteria/:id/edit",
        name: "assessment-criteria-edit",
        component: () =>
        import("../../pages/weight-management/assessment-criteria/Edit.vue"),
    },

    //------     Orientation Criteria    ------//

    {
        path: "orientation-criteria",
        name: "orientation-criteria",
        component: () =>
        import("../../pages/HRD-Criteria/orientation-criteria/Index.vue"),
    },

    {
        path: "orientation-criteria/create",
        name: "orientation-criteria-create",
        component: () =>
        import("../../pages/HRD-Criteria/orientation-criteria/Create.vue"),
    },

    {
        path: "orientation-criteria/:id/edit",
        name: "orientation-criteria-update",
        component: () =>
        import("../../pages/HRD-Criteria/orientation-criteria/Edit.vue"),
    },

    //------     OJT Criteria    ------//

    {
        path: "ojt-criteria",
        name: "ojt-criteria",
        component: () => import("../../pages/HRD-Criteria/ojt-criteria/Index.vue"),
    },

    {
        path: "ojt-criteria/create",
        name: "ojt-criteria-create",
        component: () => import("../../pages/HRD-Criteria/ojt-criteria/Create.vue"),
    },

    {
        path: "ojt-criteria/:id/edit",
        name: "ojt-criteria-update",
        component: () => import("../../pages/HRD-Criteria/ojt-criteria/Edit.vue"),
    },

    // Behavior Question

    {
        path: "hrd-behavior-question",
        name: "hrd-behavior-question",
        component: () =>
        import("../../pages/HRD-Criteria/behavior-question/BehaviorQuestion.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["hrd-behaviour-question-criteria-list"],
        },
    },
    {
        path: "hrd-behavior-question/create",
        name: "hrd-behavior-question-create",
        component: () =>
        import("../../pages/HRD-Criteria/behavior-question/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["hrd-behaviour-question-criteria-create"],
        },
    },
    {
        path: "hrd-behavior-question/:id/edit",
        name: "hrd-behavior-question-update",
        component: () =>
        import("../../pages/HRD-Criteria/behavior-question/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: [
            "hrd-behaviour-question-criteria-edit",
            "hrd-behaviour-question-criteria-list",
        ],
        },
    },

    //------     Trainer Criteria    ------//

    //  Orientation Criteria
    {
        path: "trainer-orientation-criteria",
        name: "trainer-orientation-criteria",
        component: () =>
        import(
            "../../pages/TrainerCriteria/orientation-criteria/OrientationCriteria.vue"
        ),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-orientation-criteria-list"],
        },
    },

    {
        path: "trainer-orientation-criteria/create",
        name: "trainer-orientation-criteria-create",
        component: () =>
        import("../../pages/TrainerCriteria/orientation-criteria/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-orientation-criteria-create"],
        },
    },

    {
        path: "trainer-orientation-criteria/:id/edit",
        name: "trainer-orientation-criteria-update",
        component: () =>
        import("../../pages/TrainerCriteria/orientation-criteria/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: [
            "trainer-orientation-criteria-edit",
            "trainer-orientation-criteria-list",
        ],
        },
    },

    //   In-Class Criteria

    {
        path: "trainer-inclass-criteria",
        name: "trainer-inclass-criteria",
        component: () =>
        import(
            "../../pages/TrainerCriteria/inclass-criteria/InClassCriteria.vue"
        ),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-inclass-criteria-list"],
        },
    },
    {
        path: "trainer-inclass-criteria/create",
        name: "trainer-inclass-criteria-create",
        component: () =>
        import("../../pages/TrainerCriteria/inclass-criteria/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-inclass-criteria-create"],
        },
    },
    {
        path: "trainer-inclass-criteria/:id/edit",
        name: "trainer-inclass-criteria-update",
        component: () =>
        import("../../pages/TrainerCriteria/inclass-criteria/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-inclass-criteria-edit"],
        },
    },

    // OJT Criteria

    {
        path: "trainer-ojt-criteria",
        name: "trainer-ojt-criteria",
        component: () =>
        import("../../pages/TrainerCriteria/ojt-criteria/OJTCriteria.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-ojt-criteria-list"],
        },
    },
    {
        path: "trainer-ojt-criteria/create",
        name: "trainer-ojt-criteria-create",
        component: () =>
        import("../../pages/TrainerCriteria/ojt-criteria/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-ojt-criteria-create"],
        },
    },
    {
        path: "trainer-ojt-criteria/:id/edit",
        name: "trainer-ojt-criteria-update",
        component: () =>
        import("../../pages/TrainerCriteria/ojt-criteria/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-ojt-criteria-edit", "trainer-ojt-criteria-list"],
        },
    },
    // OJT Criteria
    {
        path: "trainer-presentation-criteria",
        name: "trainer-presentation-criteria",
        component: () =>
        import(
            "../../pages/TrainerCriteria/presentation-criteria/PresentationCriteria.vue"
        ),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-presentation-criteria-list"],
        },
    },
    {
        path: "trainer-presentation-criteria/create",
        name: "trainer-presentation-criteria-create",
        component: () =>
        import("../../pages/TrainerCriteria/presentation-criteria/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-presentation-criteria-create"],
        },
    },
    {
        path: "trainer-presentation-criteria/:id/edit",
        name: "trainer-presentation-criteria-update",
        component: () =>
        import("../../pages/TrainerCriteria/presentation-criteria/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: [
            "trainer-presentation-criteria-edit",
            "trainer-presentation-criteria-list",
        ],
        },
    },
    // Competency Criteria
    {
        path: "trainer-competency-criteria",
        name: "trainer-competency-criteria",
        component: () =>
        import(
            "../../pages/TrainerCriteria/competency-criteria/CompetencyCriteria.vue"
        ),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-compentency-criteria-list"],
        },
    },
    {
        path: "trainer-competency-criteria/create",
        name: "trainer-competency-criteria-create",
        component: () =>
        import("../../pages/TrainerCriteria/competency-criteria/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-compentency-criteria-create"],
        },
    },
    {
        path: "trainer-competency-criteria/:id/edit",
        name: "trainer-competency-criteria-update",
        component: () =>
        import("../../pages/TrainerCriteria/competency-criteria/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: [
            "trainer-compentency-criteria-edit",
            "trainer-compentency-criteria-list",
        ],
        },
    },
    // Evaluation Criteria
    {
        path: "trainer-evaluation-criteria",
        name: "trainer-evaluation-criteria",
        component: () =>
        import(
            "../../pages/TrainerCriteria/evaluation-criteria/EvaluationCriteria.vue"
        ),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-evaluation-criteria-list"],
        },
    },
    {
        path: "trainer-evaluation-criteria/create",
        name: "trainer-evaluation-criteria-create",
        component: () =>
        import("../../pages/TrainerCriteria/evaluation-criteria/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-evaluation-criteria-create"],
        },
    },
    {
        path: "trainer-evaluation-criteria/:id/edit",
        name: "trainer-evaluation-criteria-update",
        component: () =>
        import("../../pages/TrainerCriteria/evaluation-criteria/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-evaluation-criteria-edit"],
        },
    },
    //  Reflection Criteria
    {
        path: "trainer-reflection-criteria",
        name: "trainer-reflection-criteria",
        component: () =>
        import(
            "../../pages/TrainerCriteria/reflection-criteria/ReflectionCriteria.vue"
        ),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-reflection-criteria-list"],
        },
    },
    {
        path: "trainer-reflection-criteria/create",
        name: "trainer-reflection-criteria-create",
        component: () =>
        import("../../pages/TrainerCriteria/reflection-criteria/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-reflection-criteria-create"],
        },
    },
    {
        path: "trainer-reflection-criteria/:id/edit",
        name: "trainer-reflection-criteria-update",
        component: () =>
        import("../../pages/TrainerCriteria/reflection-criteria/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: [
            "trainer-reflection-criteria-edit",
            "trainer-reflection-criteria-list",
        ],
        },
    },
    // Trainee to Trainer Criteria
    {
        path: "trainer-trainee-criteria",
        name: "trainer-trainee-criteria",
        component: () =>
        import(
            "../../pages/TrainerCriteria/trainee-to-trainer-criteria/TraineeToTrainerCriteria.vue"
        ),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-trainee-criteria-list"],
        },
    },
    {
        path: "trainer-trainee-criteria/create",
        name: "trainer-trainee-criteria-create",
        component: () =>
        import(
            "../../pages/TrainerCriteria/trainee-to-trainer-criteria/Form.vue"
        ),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-trainee-criteria-create"],
        },
    },
    {
        path: "trainer-trainee-criteria/:id/edit",
        name: "trainer-trainee-criteria-update",
        component: () =>
        import(
            "../../pages/TrainerCriteria/trainee-to-trainer-criteria/Form.vue"
        ),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-trainee-criteria-edit"],
        },
    },
    // Office Credits Criteria
    {
        path: "trainer-officer-credits",
        name: "trainer-officer-credits",
        component: () =>
        import("../../pages/TrainerCriteria/officer-credits/OfficerCredits.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-officer-credits-list"],
        },
    },
    {
        path: "trainer-officer-credits/create",
        name: "trainer-officer-credits-create",
        component: () =>
        import("../../pages/TrainerCriteria/officer-credits/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-officer-credits-create"],
        },
    },
    {
        path: "trainer-officer-credits/:id/edit",
        name: "trainer-officer-credits-update",
        component: () =>
        import("../../pages/TrainerCriteria/officer-credits/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-officer-credits-edit"],
        },
    },

    //------     SCHEDULE MANAGEMENT    ------//

    // Program Timeline

    {
        path: "program-timeline",
        name: "program-timeline",
        component: () =>
        import(
            "../../pages/ScheduleManagement/program-timeline/ProgramTimeline.vue"
        ),
        meta: {
        requirePermissions: true,
        permissions: ["schedule-program-timeline-list"],
        },
    },
    {
        path: "program-timeline/create",
        name: "program-timeline-create",
        component: () =>
        import("../../pages/ScheduleManagement/program-timeline/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["schedule-program-timeline-create"],
        },
    },
    {
        path: "program-timeline/:id/edit",
        name: "program-timeline-update",
        component: () =>
        import("../../pages/ScheduleManagement/program-timeline/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: [
            "schedule-program-timeline-edit",
            "schedule-program-timeline-list",
        ],
        },
    },

    // Program Allocation
    {
        path: "program-allocation",
        name: "program-allocation",
        component: () =>
        import(
            "../../pages/ScheduleManagement/program-allocation/ProgramAllocation.vue"
        ),
        meta: {
        requirePermissions: true,
        permissions: ["schedule-program-allocation-list"],
        },
    },
    {
        path: "program-allocation/create",
        name: "program-allocation-create",
        component: () =>
        import("../../pages/ScheduleManagement/program-allocation/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["schedule-program-allocation-create"],
        },
    },
    {
        path: "program-allocation/:id/edit",
        name: "program-allocation-update",
        component: () =>
        import("../../pages/ScheduleManagement/program-allocation/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: [
            "schedule-program-allocation-edit",
            "schedule-program-allocation-list",
        ],
        },
    },

    // Orientation Assessment
    {
        path: "orientation-assessment",
        name: "orientation-assessment",
        component: () =>
        import(
            "../../pages/ScheduleManagement/orientation-assessment/OrientationAssessment.vue"
        ),
        meta: {
        requirePermissions: true,
        permissions: ["schedule-orientation-assessment-list"],
        },
    },
    {
        path: "orientation-assessment/create",
        name: "orientation-assessment-create",
        component: () =>
        import("../../pages/ScheduleManagement/orientation-assessment/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["schedule-orientation-assessment-create"],
        },
    },
    {
        path: "orientation-assessment/:id/edit",
        name: "orientation-assessment-update",
        component: () =>
        import("../../pages/ScheduleManagement/orientation-assessment/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: [
            "schedule-orientation-assessment-edit",
            "schedule-orientation-assessment-list",
        ],
        },
    },

    // OJT Assessment
    {
        path: "ojt-assessment",
        name: "ojt-assessment",
        component: () =>
        import("../../pages/ScheduleManagement/ojt-assessment/OJTAssessment.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["schedule-ojt-assessment-list"],
        },
    },
    {
        path: "ojt-assessment/create",
        name: "ojt-assessment-create",
        component: () =>
        import("../../pages/ScheduleManagement/ojt-assessment/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["schedule-ojt-assessment-create"],
        },
    },
    {
        path: "ojt-assessment/:id/edit",
        name: "ojt-assessment-update",
        component: () =>
        import("../../pages/ScheduleManagement/ojt-assessment/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: [
            "schedule-ojt-assessment-edit",
            "schedule-ojt-assessment-list",
        ],
        },
    },

    {
      path: "training-history",
      name: "training-history",
      component: () =>
        import(
          "../../pages/TrainingDatabase/training-history/TrainingHistory.vue"
        ),
      meta: {
        requirePermissions: true,
        permissions: ["training-history-list"],
      },
    },
    {
      path: "evaluation-history",
      name: "evaluation-history",
      component: () =>
        import(
          "../../pages/TrainingDatabase/evaluation-history/EvaluationHistory.vue"
        ),
      meta: {
        requirePermissions: true,
        permissions: ["evaluation-history-list"],
      },
    },
    {
      path: "evaluation-history/:id/detail",
      name: "evaluation-history-detail",
      component: () =>
        import(
          "../../pages/TrainingDatabase/evaluation-history/EvaluationHistoryDetail.vue"
        ),
      meta: {
        requirePermissions: true,
        permissions: ["evaluation-history-list"],
      },
    },

    // Orientation Batch
    {
        path: "orientation-batch",
        name: "orientation-batch",
        component: () =>
        import("../../pages/orientation-batch/OrientationBatch.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["orientation-batch-list"],
        },
    },
    {
        path: "orientation-batch/create",
        name: "orientation-batch-create",
        component: () => import("../../pages/orientation-batch/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["orientation-batch-create"],
        },
    },
    {
        path: "orientation-batch/:id/edit",
        name: "orientation-batch-update",
        component: () => import("../../pages/orientation-batch/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["orientation-batch-edit", "orientation-batch-list"],
        },
    },

    // HRD Assessment

    {
        path: "hrd-orientation-assessment",
        name: "hrd-orientation-assessment",
        component: () =>
          import(
            "../../pages/HRDAssessment/orientation-assessment/OrientationAssessment.vue"
          ),
        meta: {
          requirePermissions: true,
          permissions: ["hrd-orientation-assessment-list"],
        },
      },
      {
        path: "hrd-orientation-assessment/:id/edit",
        name: "hrd-orientation-assessment-update",
        component: () =>
          import("../../pages/HRDAssessment/orientation-assessment/Form.vue"),
        meta: {
          requirePermissions: true,
          permissions: ["hrd-orientation-assessment-create"],
        },
      },
      {
        path: "hrd-orientation-assessment/:id/1",
        name: "hrd-orientation-assessment-getEditId",
        component: () =>
          import("../../pages/HRDAssessment/orientation-assessment/Form.vue"),
        meta: {
          requirePermissions: true,
          permissions: [
            "hrd-orientation-assessment-edit",
            "hrd-orientation-assessment-list",
          ],
        },
      },
      {
        path: "hrd-ojt-assessment",
        name: "hrd-ojt-assessment",
        component: () =>
          import("../../pages/HRDAssessment/ojt-assessment/OjtAssessment.vue"),
        meta: {
          requirePermissions: true,
          permissions: ["hrd-ojt-assessment-list"],
        },
      },
      {
        path: "hrd-ojt-assessment/:id/edit",
        name: "hrd-ojt-assessment-update",
        component: () =>
          import("../../pages/HRDAssessment/ojt-assessment/Form.vue"),
        meta: {
          requirePermissions: true,
          permissions: ["hrd-ojt-assessment-create"],
        },
      },
      {
        path: "hrd-ojt-assessment/:id/1",
        name: "hrd-ojt-assessment-getEditId",
        component: () =>
          import("../../pages/HRDAssessment/ojt-assessment/Form.vue"),
        meta: {
          requirePermissions: true,
          permissions: ["hrd-ojt-assessment-edit", "hrd-ojt-assessment-list"],
        },
      },
      {
        path: "hrd-functional-assessment",
        name: "hrd-functional-assessment",
        component: () =>
          import(
            "../../pages/HRDAssessment/functional-assessment/FunctionalAssessment.vue"
          ),
        meta: {
          requirePermissions: true,
          permissions: ["hrd-behaviour-assessment-list"],
        },
      },
      {
        path: "hrd-functional-assessment/:id/edit",
        name: "hrd-functional-assessment-update",
        component: () =>
          import("../../pages/HRDAssessment/functional-assessment/Form.vue"),
        meta: {
          requirePermissions: true,
          permissions: ["hrd-ojt-assessment-create"],
        },
      },
      {
        path: "hrd-functional-assessment/:id/1",
        name: "hrd-functional-assessment-getEditId",
        component: () =>
          import("../../pages/HRDAssessment/functional-assessment/Form.vue"),
        meta: {
          requirePermissions: true,
          permissions: ["hrd-ojt-assessment-edit", "hrd-ojt-assessment-list"],
        },
      },
      {
        path: "hrd-ruleregulation-assessment",
        name: "hrd-ruleregulation-assessment",
        component: () =>
          import(
            "../../pages/HRDAssessment/rule-regulation-assessment/RuleRegulationAssessment.vue"
          ),
        meta: {
          requirePermissions: true,
          permissions: ["hrd-behaviour-assessment-list"],
        },
      },
      {
        path: "hrd-ruleregulation-assessment/:id/edit",
        name: "hrd-ruleregulation-assessment-update",
        component: () =>
          import("../../pages/HRDAssessment/rule-regulation-assessment/Form.vue"),
        meta: {
          requirePermissions: true,
          permissions: ["hrd-ojt-assessment-create"],
        },
      },
      {
        path: "hrd-ruleregulation-assessment/:id/1",
        name: "hrd-ruleregulation-assessment-getEditId",
        component: () =>
          import("../../pages/HRDAssessment/rule-regulation-assessment/Form.vue"),
        meta: {
          requirePermissions: true,
          permissions: ["hrd-ojt-assessment-edit", "hrd-ojt-assessment-list"],
        },
      },
      {
        path: "hrd-ruleregulation-assessment/:id/1",
        name: "hrd-ruleregulation-assessment-getEditId",
        component: () =>
          import("../../pages/HRDAssessment/rule-regulation-assessment/Form.vue"),
        meta: {
          requirePermissions: true,
          permissions: ["hrd-ojt-assessment-edit", "hrd-ojt-assessment-list"],
        },
      },
      {
        path: "hrd-behavior-assessment",
        name: "hrd-behavior-assessment",
        component: () =>
          import(
            "../../pages/HRDAssessment/behavior-assessment/BehaviorAssessment.vue"
          ),
        meta: {
          requirePermissions: true,
          permissions: ["hrd-behaviour-assessment-list"],
        },
      },
      {
        path: "hrd-behavior-assessment/:id/:assessorId",
        name: "hrd-behavior-assessment-detail",
        component: () =>
          import("../../pages/HRDAssessment/behavior-assessment/Form.vue"),
        meta: {
          requirePermissions: true,
          permissions: ["hrd-behaviour-assessment-list"],
        },
      },
      {
        path: "hrd-orientation-evaluation",
        name: "hrd-orientation-evaluation",
        component: () =>
          import(
            "../../pages/HRDAssessment/orientation-evaluation/OrientationEvaluation.vue"
          ),
        meta: {
          requirePermissions: true,
          permissions: ["hrd-orientation-evaluation-list"],
        },
      },
      {
        path: "hrd-orientation-evaluation/:id/edit",
        name: "hrd-orientation-evaluation-update",
        component: () =>
          import("../../pages/HRDAssessment/orientation-evaluation/Form.vue"),
        meta: {
          requirePermissions: true,
          permissions: [
            "hrd-orientation-evaluation-edit",
            "hrd-orientation-evaluation-list",
          ],
        },
      },
      {
        path: "hrd-orientation-evaluation/create",
        name: "hrd-orientation-evaluation-create",
        component: () =>
          import("../../pages/HRDAssessment/orientation-evaluation/Form.vue"),
        meta: {
          requirePermissions: true,
          permissions: ["hrd-orientation-evaluation-create"],
        },
      },
      {
        path: "hrd-behavior-evaluation",
        name: "hrd-behavior-evaluation",
        component: () =>
          import(
            "../../pages/HRDAssessment/behavior-evaluation/BehaviorEvaluation.vue"
          ),
        meta: {
          requirePermissions: true,
          permissions: ["hrd-behaviour-evaluation-list"],
        },
      },
      {
        path: "hrd-behavior-evaluation/:id/edit",
        name: "hrd-behavior-evaluation-update",
        component: () =>
          import("../../pages/HRDAssessment/behavior-evaluation/Form.vue"),
        meta: {
          requirePermissions: true,
          permissions: [
            "hrd-behaviour-evaluation-edit",
            "hrd-behaviour-evaluation-list",
          ],
        },
      },
      {
        path: "hrd-behavior-evaluation/create",
        name: "hrd-behavior-evaluation-create",
        component: () =>
          import("../../pages/HRDAssessment/behavior-evaluation/Form.vue"),
        meta: {
          requirePermissions: true,
          permissions: ["hrd-behaviour-evaluation-create"],
        },
      },
      
    // end HRD Asessment

    //------     Trainer Assessment    ------//

    {
        path: "trainer-orientation-assessment",
        name: "trainer-orientation-assessment",
        component: () =>
        import(
            "../../pages/TrainerAssessment/orientation-assessment/OrientationAssessment.vue"
        ),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-orientation-assessment-list"],
        },
    },
    {
        path: "trainer-orientation-assessment/:id/edit",
        name: "trainer-orientation-assessment-update",
        component: () =>
        import("../../pages/TrainerAssessment/orientation-assessment/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: [
            "trainer-orientation-assessment-edit",
            "trainer-orientation-assessment-list",
        ],
        },
    },
    {
        path: "trainer-orientation-assessment/create",
        name: "trainer-orientation-assessment-create",
        component: () =>
        import("../../pages/TrainerAssessment/orientation-assessment/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-orientation-assessment-create"],
        },
    },
    {
        path: "trainer-inclass-assessment",
        name: "trainer-inclass-assessment",
        component: () =>
        import(
            "../../pages/TrainerAssessment/inclass-assessment/InclassAssessment.vue"
        ),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-inclass-assessment-list"],
        },
    },
    {
        path: "trainer-inclass-assessment/:id/edit",
        name: "trainer-inclass-assessment-update",
        component: () =>
        import("../../pages/TrainerAssessment/inclass-assessment/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: [
            "trainer-inclass-assessment-edit",
            "trainer-inclass-assessment-list",
        ],
        },
    },
    {
        path: "trainer-inclass-assessment/create",
        name: "trainer-inclass-assessment-create",
        component: () =>
        import("../../pages/TrainerAssessment/inclass-assessment/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-inclass-assessment-create"],
        },
    },
    {
        path: "trainer-presentation-assessment",
        name: "trainer-presentation-assessment",
        component: () =>
        import(
            "../../pages/TrainerAssessment/presentation-assessment/PresentationAssessment.vue"
        ),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-presentation-assessment-list"],
        },
    },
    {
        path: "trainer-presentation-assessment/:id/edit",
        name: "trainer-presentation-assessment-update",
        component: () =>
        import("../../pages/TrainerAssessment/presentation-assessment/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: [
            "trainer-presentation-assessment-edit",
            "trainer-presentation-assessment-list",
        ],
        },
    },
    {
        path: "trainer-presentation-assessment/create",
        name: "trainer-presentation-assessment-create",
        component: () =>
        import("../../pages/TrainerAssessment/presentation-assessment/Form.vue"),
        meta: {
        requirePermissions: true,
        permissions: ["trainer-presentation-assessment-create"],
        },
    },

  // Trainer OJT Assessment schedule
  {
    path: "trainer-ojt-assessment-schedule",
    name: "trainer-ojt-assessment-schedule",
    component: () =>
      import(
        "../../pages/TrainerAssessment/ojt-assessment-schedule/OjtAssessmentSchedule.vue"
      ),
    meta: {
      requirePermissions: true,
      permissions: ["trainer-ojt-assessment-list"],
    },
  },
  {
    path: "trainer-ojt-assessment-schedule/:id/edit",
    name: "trainer-ojt-assessment-schedule-update",
    component: () =>
      import("../../pages/TrainerAssessment/ojt-assessment-schedule/Form.vue"),
    meta: {
      requirePermissions: true,
      permissions: [
        "trainer-ojt-assessment-edit",
        "trainer-ojt-assessment-list",
      ],
    },
  },
  {
    path: "trainer-ojt-assessment-schedule/create",
    name: "trainer-ojt-assessment-schedule-create",
    component: () =>
      import("../../pages/TrainerAssessment/ojt-assessment-schedule/Form.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["trainer-ojt-assessment-create"],
    },
  },
  {
    path: "trainer-ojt-assessment",
    name: "trainer-ojt-assessment",
    component: () =>
      import("../../pages/TrainerAssessment/ojt-assessment/OjtAssessment.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["trainer-ojt-assessment-list"],
    },
  },
  {
    path: "trainer-ojt-assessment/:id",
    name: "trainer-ojt-assessment-detail",
    component: () =>
      import("../../pages/TrainerAssessment/ojt-assessment/Form.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["trainer-ojt-assessment-list"],
    },
  },
  {
    path: "trainer-competency-assessment",
    name: "trainer-competency-assessment",
    component: () =>
      import(
        "../../pages/TrainerAssessment/competency-assessment/CompetencyAssessment.vue"
      ),
    meta: {
      requirePermissions: true,
      permissions: ["trainer-compentency-assessment-list"],
    },
  },
  {
    path: "trainer-competency-assessment/:id",
    name: "trainer-competency-assessment-detail",
    component: () =>
      import("../../pages/TrainerAssessment/competency-assessment/Form.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["trainer-compentency-assessment-list"],
    },
  },
  {
    path: "orientation-award",
    name: "orientation-award",
    component: () =>
      import(
        "../../pages/TrainerAssessment/orientation-award/OrientationAward.vue"
      ),
    meta: {
      requirePermissions: true,
      permissions: ["trainer-orientation-award-list"],
    },
  },
  {
    path: "orientation-award/:id/edit",
    name: "orientation-award-update",
    component: () =>
      import("../../pages/TrainerAssessment/orientation-award/Form.vue"),
    meta: {
      requirePermissions: true,
      permissions: [
        "trainer-orientation-award-edit",
        "trainer-orientation-award-list",
      ],
    },
  },
  {
    path: "orientation-award/create",
    name: "orientation-award-create",
    component: () =>
      import("../../pages/TrainerAssessment/orientation-award/Form.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["trainer-orientation-award-create"],
    },
  },
  {
    path: "peer-to-peer-assessment",
    name: "peer-to-peer-assessment",
    component: () =>
      import(
        "../../pages/TrainerAssessment/peer-to-peer-assessment/PeerToPeerAssessment.vue"
      ),
    meta: {
      requirePermissions: true,
      permissions: ["trainer-peer-to-peer-assessment-list"],
    },
  },
  {
    path: "trainee-to-trainer-assessment",
    name: "trainee-to-trainer-assessment",
    component: () =>
      import(
        "../../pages/TrainerAssessment/trainee-to-trainer-assessment/TraineeToTrainerAssessment.vue"
      ),
    meta: {
      requirePermissions: true,
      permissions: ["trainer-trainee-to-trainer-assessment-list"],
    },
  },
  {
    path: "trainee-to-trainer-assessment/:id",
    name: "trainee-to-trainer-assessment-detail",
    component: () =>
      import(
        "../../pages/TrainerAssessment/trainee-to-trainer-assessment/Form.vue"
      ),
    meta: {
      requirePermissions: true,
      permissions: ["trainer-trainee-to-trainer-assessment-list"],
    },
  },
  {
    path: "training-reflection",
    name: "training-reflection",
    component: () =>
      import(
        "../../pages/TrainerAssessment/training-reflection/TrainingReflection.vue"
      ),
    meta: {
      requirePermissions: true,
      permissions: ["trainer-training-reflection-list"],
    },
  },
  {
    path: "training-reflection/:id/edit",
    name: "training-reflection-update",
    component: () =>
      import("../../pages/TrainerAssessment/training-reflection/Form.vue"),
    meta: {
      requirePermissions: true,
      permissions: [
        "trainer-training-reflection-edit",
        "trainer-training-reflection-list",
      ],
    },
  },
  {
    path: "training-reflection/create",
    name: "training-reflection-create",
    component: () =>
      import("../../pages/TrainerAssessment/training-reflection/Form.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["trainer-training-reflection-create"],
    },
  },

  //------   End  Trainer Criteria    ------//

  //------   Assessment Form    ------//

  {
    path: "assessment-form",
    name: "assessment-form",
    component: () =>
      import(
        "../../pages/MasterData/assessment-form/Index.vue"
      ),
    meta: {
      requirePermissions: true,
      permissions: ["assessment-form-list"],
    },
  },
  {
    path: "assessment-form/:id/edit",
    name: "assessment-form-update",
    component: () =>
      import("../../pages/MasterData/assessment-form/Edit.vue"),
    meta: {
      requirePermissions: true,
      permissions: [
        "assessment-form-edit",
        "assessment-form-list",
      ],
    },
  },
  {
    path: "assessment-form/create",
    name: "assessment-form-create",
    component: () =>
      import("../../pages/MasterData/assessment-form/Create.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["assessment-form-create"],
    },
  },

  //------   End  Assessment Form    ------//

  //     After Training Assessment
  {
    path: "after-trainer-question-assessment",
    name: "after-trainer-question-assessment",
    component: () =>
      import(
        "../../pages/AfterTrainerAssessment/question-create-form/QuestionAssessment.vue"
      ),
    meta: {
      requirePermissions: true,
      permissions: ["ata-question-list"],
    },
  },
  {
    path: "after-trainer-question-assessment/create",
    name: "after-trainer-question-assessment/create",
    component: () =>
      import(
        "../../pages/AfterTrainerAssessment/question-create-form/QuestionCreate.vue"
      ),
    meta: {
      requirePermissions: true,
      permissions: ["ata-question-create"],
    },
  },
  {
    path: "after-trainer-question-assessment/edit/:id",
    name: "after-trainer-question-assessment/edit",
    component: () =>
      import(
        "../../pages/AfterTrainerAssessment/question-create-form/QuestionEdit.vue"
      ),
    meta: {
      requirePermissions: true,
      permissions: ["ata-question-edit", "ata-question-list"],
    },
  },
  {
    path: "assessment-module",
    name: "assessment-module",
    component: () =>
      import("../../pages/AfterTrainerAssessment/assessment-module/Module.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["ata-assessment-module-list"],
    },
  },
  {
    path: "assessment-module/create",
    name: "assessment-module/create",
    component: () =>
      import(
        "../../pages/AfterTrainerAssessment/assessment-module/ModuleCreate.vue"
      ),
    meta: {
      requirePermissions: true,
      permissions: ["ata-assessment-module-create"],
    },
  },
  {
    path: "assessment-module/edit/:module_id",
    name: "assessment-module/edit",
    component: () =>
      import(
        "../../pages/AfterTrainerAssessment/assessment-module/ModuleEdit.vue"
      ),
    meta: {
      requirePermissions: true,
      permissions: ["ata-assessment-module-edit", "ata-assessment-module-list"],
    },
  },
  {
    path: "assessment-timeline",
    name: "assessment-timeline",
    component: () =>
      import(
        "../../pages/AfterTrainerAssessment/assessment-timeline/AssessmentTimeline.vue"
      ),
    meta: {
      requirePermissions: true,
      permissions: ["ata-assessment-timeline-list"],
    },
  },
  {
    path: "assessment-timeline/edit/:id",
    name: "assessment-timeline/edit",
    component: () =>
      import("../../pages/AfterTrainerAssessment/assessment-timeline/Edit.vue"),
    meta: {
      requirePermissions: true,
      permissions: [
        "ata-assessment-timeline-edit",
        "ata-assessment-timeline-list",
      ],
    },
  },
  {
    path: "assessment-result",
    name: "assessment-result",
    component: () =>
      import(
        "../../pages/AfterTrainerAssessment/superior-hr-assessment-result/AfterAssessmentResult.vue"
      ),
    meta: {
      requirePermissions: true,
      permissions: ["ata-assessment-result-list"],
    },
  },
  {
    path: "assessment-result/edit/:id",
    name: "assessment-result/edit",
    component: () =>
      import(
        "../../pages/AfterTrainerAssessment/superior-hr-assessment-result/Edit.vue"
      ),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      permissions: ["ata-assessment-result-list"],
    },
  },

  {
    path: "hrd-orientation-assessment-report",
    name: "hrd-orientation-assessment-report",
    component: () =>
      import("../../pages/reporting/Hrd/OrientationAssessmentReport.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["hrd-orientation-assessment-report-list"],
    },
  },
  {
    path: "hrd-ojt-assessment-report",
    name: "hrd-ojt-assessment-report",
    component: () =>
      import("../../pages/reporting/Hrd/OjtAssessmentReport.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["hrd-ojt-assessment-report-list"],
    },
  },
  {
    path: "individual-orientation-assessment-report",
    name: "individual-orientation-assessment-report",
    component: () =>
      import(
        "../../pages/reporting/Hrd/IndividualOrientationAssessmentReport.vue"
      ),
    meta: {
      requirePermissions: true,
      permissions: ["hrd-orientation-individual-assessment-report-list"],
    },
  },
  {
    path: "individual-ojt-assessment-report",
    name: "individual-ojt-assessment-report",
    component: () =>
      import("../../pages/reporting/Hrd/IndividualOjtAssessmentReport.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["hrd-ojt-individual-assessment-report-list"],
    },
  },
  {
    path: "assessment-schedule-report",
    name: "assessment-schedule-report",
    component: () =>
      import("../../pages/reporting/Hrd/AssessmentScheduleReport.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["hrd-assessment-schedule-report-list"],
    },
  },
    /**After Training  Assessment Report*/

  {
    path: "ata-summary-report",
    name: "ata-summary-report",
    component: () => import("../../pages/reporting/ATA/ATASummaryReport.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["ata-summary-report-list"],
    },
  },
  {
    path: "ata-individual-report",
    name: "ata-individual-report",
    component: () =>
      import("../../pages/reporting/ATA/ATAIndividualReport.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["ata-individual-report-list"],
    },
  },
  {
    path: "achievement-detail-report",
    name: "achievement-detail-report",
    component: () =>
      import("../../pages/reporting/ATA/AchievementDetailReport.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["ata-achievement-detail-report-list"],
    },
  },

  {
    path: "training-module-cancel-report",
    name: "training-module-cancel-report",
    component: () =>
      import("../../pages/reporting/Training/ModuleCancelListReport.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["module-cancel-report-list"],
    },
  },
  {
    path: "training-monthly-training-plan-report",
    name: "training-monthly-training-plan-report",
    component: () =>
      import("../../pages/reporting/Training/MonthlyTrainingListReport.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["monthly-training-plan-report-list"],
    },
  },
  {
    path: "training-attendance-report",
    name: "training-attendance-report",
    component: () =>
      import("../../pages/reporting/Training/TotalAttendanceReport.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["training-attendance-report-list"],
    },
  },
  {
    path: "training-class-report",
    name: "training-class-report",
    component: () => import("../../pages/reporting/Training/ClassReport.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["number-of-classes-report-list"],
    },
  },
  {
    path: "cancel-menpower-report",
    name: "cancel-menpower-report",
    component: () =>
      import("../../pages/reporting/Training/CancelMenPowerListReport.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["cancel-man-power-report-list"],
    },
  },
  {
    path: "training-database-list-report",
    name: "training-database-list-report",
    component: () =>
      import("../../pages/reporting/Training/TrainingDatabaseReport.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["training-database-report-list"],
    },
  },
  {
    path: "training-evaluation-achievement-report",
    name: "training-evaluation-achievement-report",
    component: () =>
      import("../../pages/reporting/Training/EvaulationAchievementReport.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["evaluation-achievement-report-list"],
    },
  },
  {
    path: "training-evaluation-result-report",
    name: "training-evaluation-result-report",
    component: () =>
      import("../../pages/reporting/Training/EvaluationResultListReport.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["evaluation-result-report-list"],
    },
  },
  {
    path: "training-evaluation-suggestion-report",
    name: "training-evaluation-suggestion-report",
    component: () =>
      import(
        "../../pages/reporting/Training/EvaluationSuggestionListReport.vue"
      ),
    meta: {
      requirePermissions: true,
      permissions: ["evaluation-suggestion-report-list"],
    },
  },
  {
    path: "training-enrollment-list-report",
    name: "training-enrollment-list-report",
    component: () =>
      import("../../pages/reporting/Training/EnrollmentListReport.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["enrollment-list-report-list"],
    },
  },
  {
    path: "orientation-evaluation-management",
    name: "orientation-evaluation-management",
    component: () =>
      import(
        "../../pages/EvaluationManagement/orientation-evaluation/OrientationEvaluation.vue"
      ),
    meta: {
      requirePermissions: true,
      permissions: ["orientation-evaluation-list"],
    },
  },
  {
    path: "orientation-evaluation-management/:id/edit",
    name: "orientation-evaluation-management-update",
    component: () =>
      import(
        "../../pages/EvaluationManagement/orientation-evaluation/Form.vue"
      ),
    meta: {
      requirePermissions: true,
      permissions: [
        "orientation-evaluation-edit",
        "orientation-evaluation-list",
      ],
    },
  },
  {
    path: "orientation-evaluation-management/create",
    name: "orientation-evaluation-management-create",
    component: () =>
      import(
        "../../pages/EvaluationManagement/orientation-evaluation/Form.vue"
      ),
    meta: {
      requirePermissions: true,
      permissions: ["orientation-evaluation-create"],
    },
  },
  {
    path: "inclass-evaluation-management",
    name: "inclass-evaluation-management",
    component: () =>
      import(
        "../../pages/EvaluationManagement/inclass-evaluation/InclassEvaluation.vue"
      ),
    meta: {
      requirePermissions: true,
      permissions: ["inclass-evaluation-list"],
    },
  },
  {
    path: "inclass-evaluation-management/:id/edit",
    name: "inclass-evaluation-management-update",
    component: () =>
      import("../../pages/EvaluationManagement/inclass-evaluation/Form.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["inclass-evaluation-edit", "inclass-evaluation-list"],
    },
  },
  {
    path: "inclass-evaluation-management/create",
    name: "inclass-evaluation-management-create",
    component: () =>
      import("../../pages/EvaluationManagement/inclass-evaluation/Form.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["inclass-evaluation-create"],
    },
  },
  {
    path: "presentation-evaluation-management",
    name: "presentation-evaluation-management",
    component: () =>
      import(
        "../../pages/EvaluationManagement/presentation-evaluation/PresentationEvaluation.vue"
      ),
    meta: {
      requirePermissions: true,
      permissions: ["presentation-evaluation-list"],
    },
  },
  {
    path: "presentation-evaluation-management/:id/edit",
    name: "presentation-evaluation-management-update",
    component: () =>
      import(
        "../../pages/EvaluationManagement/presentation-evaluation/Form.vue"
      ),
    meta: {
      requirePermissions: true,
      permissions: [
        "presentation-evaluation-edit",
        "presentation-evaluation-list",
      ],
    },
  },
  {
    path: "presentation-evaluation-management/create",
    name: "presentation-evaluation-management-create",
    component: () =>
      import(
        "../../pages/EvaluationManagement/presentation-evaluation/Form.vue"
      ),
    meta: {
      requirePermissions: true,
      permissions: ["presentation-evaluation-create"],
    },
  },
  {
    path: "ojt-evaluation-management",
    name: "ojt-evaluation-management",
    component: () =>
      import(
        "../../pages/EvaluationManagement/ojt-evaluation/OjtEvaluation.vue"
      ),
    meta: {
      requirePermissions: true,
      permissions: ["ojt-evaluation-list"],
    },
  },
  {
    path: "ojt-evaluation-management/:id/edit",
    name: "ojt-evaluation-management-update",
    component: () =>
      import("../../pages/EvaluationManagement/ojt-evaluation/Form.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["ojt-evaluation-edit", "ojt-evaluation-list"],
    },
  },
  {
    path: "ojt-evaluation-management/create",
    name: "ojt-evaluation-management-create",
    component: () =>
      import("../../pages/EvaluationManagement/ojt-evaluation/Form.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["ojt-evaluation-create"],
    },
  },
  {
    path: "competency-evaluation-management",
    name: "competency-evaluation-management",
    component: () =>
      import(
        "../../pages/EvaluationManagement/competency-evaluation/CompetencyEvaluation.vue"
      ),
    meta: {
      requirePermissions: true,
      permissions: ["compentency-evaluation-list"],
    },
  },
  {
    path: "competency-evaluation-management/:id/edit",
    name: "competency-evaluation-management-update",
    component: () =>
      import("../../pages/EvaluationManagement/competency-evaluation/Form.vue"),
    meta: {
      requirePermissions: true,
      permissions: [
        "compentency-evaluation-edit",
        "compentency-evaluation-list",
      ],
    },
  },
  {
    path: "competency-evaluation-management/create",
    name: "competency-evaluation-management-create",
    component: () =>
      import("../../pages/EvaluationManagement/competency-evaluation/Form.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["compentency-evaluation-create"],
    },
  },
  {
    path: "grading-level-management",
    name: "grading-level-management",
    component: () =>
      import("../../pages/GradingManagement/GradingLevel/GradingLevel.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["grading-level-list"],
    },
  },
  {
    path: "grading-level-management/:id/edit",
    name: "grading-level-management-update",
    component: () =>
      import("../../pages/GradingManagement/GradingLevel/Form.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["grading-level-edit", "grading-level-list"],
    },
  },
  {
    path: "grading-level-management/create",
    name: "grading-level-management-create",
    component: () =>
      import("../../pages/GradingManagement/GradingLevel/Form.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["grading-level-create"],
    },
  },
  {
    path: "grading-evaluation-management",
    name: "grading-evaluation-management",
    component: () =>
      import(
        "../../pages/GradingManagement/GradingEvaluation/GradingEvaluation.vue"
      ),
    meta: {
      requirePermissions: true,
      permissions: ["grading-evaluation-list"],
    },
  },
  {
    path: "grading-evaluation-management/:id/edit",
    name: "grading-evaluation-management-update",
    component: () =>
      import("../../pages/GradingManagement/GradingEvaluation/Form.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["grading-evaluation-edit", "grading-evaluation-list"],
    },
  },
  {
    path: "grading-evaluation-management/create",
    name: "grading-evaluation-management-create",
    component: () =>
      import("../../pages/GradingManagement/GradingEvaluation/Form.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["grading-evaluation-create"],
    },
  },
  {
    path: "increment-management-request",
    name: "increment-management-request",
    component: () =>
      import("../../pages/IncrementManagement/request/Request.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["increment-management-list"],
    },
  },
  {
    path: "increment-management-request/:id/edit",
    name: "increment-management-request-update",
    component: () => import("../../pages/IncrementManagement/request/Form.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["increment-management-edit", "increment-management-list"],
    },
  },
  {
    path: "increment-management-request/create",
    name: "increment-management-request-create",
    component: () => import("../../pages/IncrementManagement/request/Form.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["increment-management-create"],
    },
  },
  {
    path: "increment-management-approval",
    name: "increment-management-approval",
    component: () =>
      import("../../pages/IncrementManagement/approval/Approval.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["increment-management-list"],
    },
  },
  {
    path: "increment-management-approval/detail/:id",
    name: "increment-management-approval-detail",
    component: () =>
      import("../../pages/IncrementManagement/approval/Form.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["increment-management-list"],
    },
  },
  {
    path: "increment-management-approval/save",
    name: "increment-management-approval-save",
    component: () =>
      import("../../pages/IncrementManagement/approval/Form.vue"),
    meta: {
      requirePermissions: true,
      permissions: ["increment-management-create"],
    },
  },
  {
    path: "enroll-usage-count-report",
    name: "enroll-usage-count-report",
    component: () =>
      import("../../pages/reporting/EnrollList/EnrollUsageCount.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  

];

export default adminRoutes;
